(function($) {
	subMenu = function() {
		$(document).ready(function() {
			var url = window.location.href;

			$(".sub-menu select option").each(function() {
				if($(this).data('href') === url) {
					$(this).attr('selected', 'selected');
				}
			});
		});
		
		$(".sub-menu select").change(function() {
			var url = $(this).find(':selected').data('href');
			window.location.href = url;
		});
	};
})(jQuery);
