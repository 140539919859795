/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        moment.locale('sv');


        // JavaScript to be fired on all pages
        $(document).foundation(); // Foundation JavaScript

        //init webnerds functions
        stickyHeader();
        mobileNav();
        autoScrollToAnchor();
        siteHeaderSubMenu();
        questionsAndAnswers();
        //gallery();
        instagramViewer();
        subMenu();

        $('.carousel-container').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            prevArrow: '<button type="button" class="slick-prev">Previous</button>',
            nextArrow: '<button type="button" class="slick-next">Next</button>',
            adaptiveHeight: true
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    //Blog
    'blog': {
        init: function() {
            initBlog();
        }
    },

    'page_template_coworkers' : {
      init: function() {
        $('#coworkers-filter-list a').click(function(e) {
          e.preventDefault();
          var filter = $(this).data('filter');

          $('#coworkers-filter-list a').removeClass("active");
          $(this).addClass("active");
          if(filter === 'show-all') {
            $('.department').hide();
            $('.all-coworkers').show();
          }else {
            $('.all-coworkers').hide();
            $('.department').hide();
            $('.department.'+filter).show();
          }
          console.log(filter);
        });
      }
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
