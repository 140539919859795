

(function($) {
  instagramViewer = function() {
    $instagramViewers = $('.instagram-viewer-js');
    $.each($instagramViewers, function(index, value) {
        var target = $(value).data('id');
        var accessToken = $(value).data('access-token');
        var userId = $(value).data('user-id');
        var limit = $(value).data('limit');

        console.log(target,accessToken,userId,limit);

        var template = '<div class="instagram-post"><img src="{{image}}" /><div class="image-meta"><img class="user-image" src="{{model.user.profile_picture}}" /><div class="username">{{model.full_name_with_fallback}}</div><div class="date-created">{{model.create_time_ago}}</div></div><div class="caption"> {{model.short_caption}}</div><a href="{{link}}" class="link" target="_blank"></a></div>';

        function filter(image) {
          var MAX_LENGTH = 140;
          // here we create a property called "short_caption"
          // on the image object, using the original caption
          if (image.caption && image.caption.text && image.caption.text.length > MAX_LENGTH) {
            image.short_caption = image.caption.text.slice(0, MAX_LENGTH) + "...";
          } else if(image.caption && image.caption.text){
            image.short_caption = image.caption.text;
          }else {
            image.short_caption = "";
          }

          //image username
          if (image.user && image.user.full_name && image.user.full_name.length > 0) {
            image.full_name_with_fallback = image.user.full_name;
          } else {
            image.full_name_with_fallback = image.user.username;
          }

          //time ago
          var imageDate = new Date(parseInt(image.created_time* 1000));
          var timeAgo = moment(imageDate).lang("sv").fromNow();

          image.create_time_ago = timeAgo;

          // ensure the filter doesn't reject any images
          return true;
        }

        function slickInstagram(target) {
            $(target).slick({
              slidesToShow: 3,
              slidesToScroll: 1,
              prevArrow: '<button type="button" class="slick-prev">Previous</button>',
              nextArrow: '<button type="button" class="slick-next">Next</button>',

              responsive: [
                {
                  breakpoint: 1200,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                  }
                },
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }

              ]
            });
        }

        var nextButton = $("#"+target+"-next");
        
        var feed = new Instafeed({
            get: 'user',
            userId: userId,
            accessToken: accessToken,
            target: target,
            resolution: 'standard_resolution',
            limit: limit,
            filter: filter,
            template: template,
            after: function() {
              slickInstagram("#"+target);
            },
        });
        feed.run();
    });
    
  };

  
})(jQuery);

